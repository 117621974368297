import React from 'react';
import {Link} from "gatsby";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    navLink: {
        fontWeight: "normal",
        paddingRight: "20px",
        textTransform: 'none',
    },
    linkDiv: {
        paddingBottom: "20px"
    }
}));

const BlogTag = () => {
    const classes = useStyles();
    return (
        <div className={classes.linkDiv}>
            <Link
                variant="button"
                color="textPrimary"
                to="/blog"
                className={classes.navLink}
                activeStyle={{ color: "black" }}
            >
                Blog Home
            </Link>
            <Link
                variant="button"
                color="textPrimary"
                to="/category/engineering-management/"
                className={classes.navLink}
                activeStyle={{ color: "black" }}
            >
                Engineering Management
            </Link>
            <Link
                variant="button"
                color="textPrimary"
                to="/category/performance-management"
                className={classes.navLink}
                activeStyle={{ color: "black" }}
            >
                Performance Management
           </Link>
            <Link
                variant="button"
                color="textPrimary"
                to="/category/leadership"
                className={classes.navLink}
                activeStyle={{ color: "black" }}
            >
                Leadership
           </Link>
            <Link
                variant="button"
                color="textPrimary"
                to="/category/process"
                className={classes.navLink}
                activeStyle={{ color: "black" }}
            >
                Process
           </Link>
        </div>
    )
}

export default BlogTag